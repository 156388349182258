import React from 'react';
import AboutUsComp from '../../components/frontend-components/about-us-comp'

// components

const AboutUs = () => {
    return (
        <>
            <AboutUsComp />
        </>
    )
}

export default AboutUs;
