export enum UserType {
    ADMIN = "ADMIN",
    EDITOR = 'EDITOR',
    USER = 'USER'
}

export enum ORDER_STATUS {
    PENDING = 'PENDING',
    PROOFED = 'PROOFED',
    APPROVED = 'APPROVED',
    DECLINED = 'DECLINED',
    COMPLETED = 'COMPLETED',
    CANCLED = 'CANCLED'
}

export enum INVOICE_STATUS {
    PENDING = "PENDING",
    APPROVED = "APPROVED",
    BLOCKED = "BLOCKED",
    DELETED = "DELETED",
};

export enum INVOICE_CURRENCY {
  USD = "USD",
  NGN = "NGN",
  GBP = "GBP",
}

export enum PAYMETN_GATEWAY {
    PAYSTACK = "PAYSTACK",
    STRIPE = "STRIPE",
    PAYPAL = "PAYPAL",
    GOOGLE_WALLET = "GOOGLE_WALLET",
    FREEXIT_WALLET = "FREEXIT_WALLET",
}

export enum PAYMENT_METHOD {
    CASH = "CASH",
    GATEWAY = "GATEWAY",
    TRANSFER = "TRANSFER",
    WALLET = "WALLET",
}
  
  